/**
 * @owner @Appboy/messaging-and-automation
 * @fileoverview i18n utilities for labelling time units
 */

import type { I18nT } from "@lib/dashboardI18n";

export type TimeUnitLabelsTrans = I18nT<"time-units">;

const timeUnitLabelsGetter = (t: I18nT<"time-units">) => ({
  seconds: t("seconds", "Seconds"),
  minutes: t("minutes", "Minutes"),
  hours: t("hours", "Hours"),
  days: t("days", "Days"),
  "calendar days": t("calendar-days", "Calendar days"),
  weeks: t("weeks", "weeks"),
});

export type TimeUnitLabelsOptions = keyof ReturnType<typeof timeUnitLabelsGetter>;

export default timeUnitLabelsGetter;
